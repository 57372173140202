import React, {Component, useEffect, useState} from 'react';
import UsuarioService from '../../../services/UsuarioService';
import { Editor } from "react-draft-wysiwyg";
import { Link } from 'react-router-dom';
import { convertToRaw, EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Swal from 'sweetalert2';
import ServerHelper from '../../../helpers/ServerHelper';

export class EditPost extends Component{
    constructor(props){
        super(props);
        this.state = {
            id: this.props.match.params.id,
            titulo: "",
            texto: "",
            editorState: EditorState.createEmpty(),
            loading: false
        }

        this.setTitulo = this.setTitulo.bind(this);
        this.publish = this.publish.bind(this);
    }

    componentDidMount(){
        if(!UsuarioService.IsAuthenticated){
            this.props.history.push('/');
        }

        this.GetPost();
    }

    GetPost = async() => {
        const response = await fetch(`${ServerHelper.ApiUrl}/blog/getall`);

        let posts = [];

        if(response.status === 200){
            posts = await response.json();

            let post = posts.filter(x => x.id == this.state.id)[0];

            const blocksFromHtml = htmlToDraft(post.texto);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            this.setState({
                titulo: post.titulo,
                editorState: editorState
            });
        }

        
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState: editorState
        });
      };

    setTitulo(event){
        this.setState({ titulo: event.target.value })
    }

    validarCampos(dto){
        let message = null;

        if(dto.titulo == ""){
            message = "Debe completar el campo titulo";
        }
        else if(dto.texto == "<p></p>"){
            message = "Debe completar el campo texto";
        }

        return message;
    }

    publish = async() => {
        var data = {
            id: this.state.id,
            titulo: this.state.titulo,
            texto: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).trim()
        }

        let message = this.validarCampos(data);

        if(message == null){
            this.setState({ loading: true });

            const url = `${ServerHelper.ApiUrl}/blog/editpost`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if(response.status == 200){
                Swal.fire({
                    icon: 'success',
                    title: '¡Se ha publicado el post!',
                    timer: 1500
                });

                this.props.history.push("/admin/blog/list")
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Parece que algo salió mal, intentalo nuevamente',
                    timer: 1500
                });
            }
            this.setState({ loading: false });
        } else {
            Swal.fire({
                icon: 'warning',
                text: message,
                timer: 1500
            });
        }
        
    }

    renderPublishButton(){
        return(
            <button type="button" className="btn btn-blue-cta btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-4" onClick={this.publish}>
                Publicar cambios<i className="fas fa-chevron-right font-weight-bolder ml-3"></i>
            </button>
        );
    }

    renderPublishLoading(){
        return(
            <button type="button" className="btn btn-blue-cta btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-4" disabled="disabled">
                Publicando<i className="fa fa-spinner fa-spin ml-3"></i>
            </button>
        );
    }

    render(){
        let publishBtn = this.state.loading ? this.renderPublishLoading() : this.renderPublishButton();
        return(
            <div className="admin-container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 p-0">
                        <div className="form-group">
                            <label className="add-post-title text-uppercase">Título</label>
                            <input type="text" className="form-control bg-lightshadow" onChange={this.setTitulo} value={this.state.titulo}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-3">
                    <div className="col-md-6 col-sm-12 p-0">
                        <div >
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-editor"
                                onEditorStateChange={this.onEditorStateChange}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 text-right m-0 p-0">
                        <Link to="/admin/blog/list" className="btn btn-blue-line btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-4 mr-2">
                            Descartar cambios X
                        </Link>
                        {publishBtn}
                    </div>
                </div>
            </div>
        );
    }
}