import React from 'react';

export class PostTitle extends React.Component {
    render(){
        return(
            <h5 className="text-uppercase fjalla">
                {this.props.children}
            </h5>
        );
    }
}