import React, {Component} from 'react';
import UsuarioService from '../../../services/UsuarioService';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import Swal from 'sweetalert2';
import ServerHelper from '../../../helpers/ServerHelper';

export class AddPost extends Component{
    constructor(props){
        super(props);
        this.state = {
            titulo: "",
            texto: "",
            editorState: EditorState.createEmpty(),
            loading: false
        }

        this.setTitulo = this.setTitulo.bind(this);
        this.publish = this.publish.bind(this);
    }

    componentDidMount(){
        if(!UsuarioService.IsAuthenticated){
            this.props.history.push('/');
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState: editorState
        });
      };

    setTitulo(event){
        this.setState({ titulo: event.target.value })
    }

    validarCampos(dto){
        let message = null;

        if(dto.titulo === ""){
            message = "Debe completar el campo titulo";
        }
        else if(dto.texto === "<p></p>"){
            message = "Debe completar el campo texto";
        }

        return message;
    }

    publish = async() => {
        var data = {
            titulo: this.state.titulo,
            texto: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).trim()
        }

        let message = this.validarCampos(data);

        if(message === null){
            this.setState({ loading: true });

            const url = `${ServerHelper.ApiUrl}/blog/addpost`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if(response.status === 200){
                Swal.fire({
                    icon: 'success',
                    title: '¡Se ha publicado el post!',
                }).then(() => {
                    this.props.history.push("/admin/blog/list")
                });
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Parece que algo salió mal, intentalo nuevamente',
                });
            }
            this.setState({ loading: false });
        } else {
            Swal.fire({
                icon: 'warning',
                text: message,
                timer: 1500
            });
        }
        
    }

    renderPublishButton(){
        return(
            <button type="button" className="btn btn-blue-cta btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-4" onClick={this.publish}>
                Publicar<i className="fas fa-chevron-right font-weight-bolder ml-3"></i>
            </button>
        );
    }

    renderPublishLoading(){
        return(
            <button type="button" className="btn btn-blue-cta btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-4" disabled="disabled">
                Publicando<i className="fa fa-spinner fa-spin ml-3"></i>
            </button>
        );
    }

    render(){
        let publishBtn = this.state.loading ? this.renderPublishLoading() : this.renderPublishButton();
        return(
            <div className="admin-container add-post-container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 px-0">
                        <div className="form-group">
                            <label className="add-post-title text-uppercase p-0 m-0">Agregar nueva entrada</label><br/>
                            <label className="add-post-txt text-uppercase">EMPIEZA A ESCRIBIR PARA TU BLOG</label>
                            <input type="text" className="form-control bg-lightshadow" placeholder="Añadir título..." onChange={this.setTitulo}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-3">
                    <div className="col-md-6 col-sm-12 px-0">
                        <div>
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-editor"
                                onEditorStateChange={this.onEditorStateChange}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 text-right">
                        {publishBtn}
                    </div>
                </div>
            </div>
        );
    }
}