import React from 'react';
import { Icon } from '../shared/Icon';

export class PostReadMore extends React.Component {
    render(){
        return(
            <div className="d-flex align-items-center readMore">
                <p className="my-2 font-weight-bolder">Leer más</p>
                <Icon icon="chevron-right" css="mx-2"></Icon>
            </div>
        );
    }
}