import React from 'react';

export class ThanksDescription extends React.Component{
  render(){
    return(
      <p className="mb-0">{this.props.children}</p>
    );
  }
}

    