import React from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import JsHelper from '../../../helpers/JsHelper';
import ServerHelper from '../../../helpers/ServerHelper';

export class PostList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            posts: []
        }

        this.PromptDelete = this.PromptDelete.bind(this);
    }

    componentDidMount(){
        this.GetPosts();
    }

    GetPosts = async() =>{
        const response = await fetch(`${ServerHelper.ApiUrl}/blog/getall`);

        if(response.status === 200){
            this.setState({ posts: await response.json() });
        }
    }

    PromptDelete = async(id) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "Se eliminará el post seleccionado y todos sus comentarios",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {

            if (result.isConfirmed) {

                var data = {
                    id: id
                }

                const url = `${ServerHelper.ApiUrl}/blog/deletepost`;

                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if(response.status === 200){
                    Swal.fire({
                        icon: 'success',
                        title: '¡Se ha eliminado el post!',
                    }).then(() => {
                        this.GetPosts();
                    });
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Parece que algo salió mal, intentalo nuevamente',
                    });
                }
            }
        });
    }

    renderPostTable(posts){
        return(
            <div className="">
                <table className="table table-hover table-sm table-row">
                    <thead>
                        <tr className="bg-lightshadow text-uppercase my-2">
                            <th className="post-counter text-center" style={{borderBottom: "3px solid #1D416E"}}cscope="col">Publicadas 
                                <div className="num-counter">{this.state.posts.length}</div>
                            </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>

                        <tr className="bg-lightshadow text-uppercase my-2 post-entry-container">
                            <th className="post-entry text-left px-3 py-4" cscope="col">Entradas</th>
                            <th scope="col"></th>
                            <th className="text-right" scope="col">
                                <Link className="btn btn-blue-cta btn-rem text-uppercase font-weight-bolder rounded-pill py-2 px-3 m-2" to="/admin/blog/addpost">Añadir nueva entrada<i className="fas fa-chevron-right font-weight-bolder ml-3"></i></Link>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {posts.map(post =>
                        <tr className="bg-lightshadow" key={post.id}>
                            <td colspan="2" className="text-left text-uppercase pl-3 pt-2 ad-post-title w-50">{post.titulo}<br/>
                                <i className="far fa-clock mr-2 py-2 ad-post-date">
                                    <span className="ml-2">{JsHelper.dateFormatting(post.fecha)}</span>
                                </i>
                            </td>
                            <td className="text-right post-options py-2">
                                <Link to={`/admin/blog/editpost/${post.id}`} className="btn btn-sm text-white mr-2">
                                    <i className="fa fa-pencil-alt">
                                        <span className="text-uppercase ml-3">Editar</span>
                                    </i>
                                </Link>
                                <Link to={`/blog/entry/${post.id}`} className="btn btn-sm text-white mr-2">
                                    <i className="fa fa-eye">
                                        <span className="text-uppercase ml-3">Ver</span>
                                    </i>
                                </Link>
                                <button className="btn btn-sm" onClick={() => this.PromptDelete(post.id)}>
                                    <i className="fa fa-trash-alt">
                                        <span className="text-uppercase ml-3">Eliminar</span>
                                    </i>
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }

    render(){
        let table = this.renderPostTable(this.state.posts);
        return(
            <div className="admin-container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12">
                        <label className="add-post-title text-uppercase p-0 m-0">Entradas</label><br/>
                        <label className="add-post-txt text-uppercase">Crea, edita y gestiona las entradas de tu blog</label>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 px-0">
                        {table}
                    </div>
                </div>
            </div>
        );
    }
}