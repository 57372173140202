import React, { Component, Fragment } from 'react';
import UsuarioService from '../../services/UsuarioService';
import { NavMenu } from '../shared/NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    UsuarioService.IsAuthenticated = UsuarioService.Get() == null ? false : true;
    return (
      <div>
        <NavMenu />
        <Fragment>
            {this.props.children}
        </Fragment>
      </div>
    );
  }
}
