import React from 'react';

export class PostDate extends React.Component {
    render(){
        return(
            <p className="m-0">
                {this.props.children}
            </p>
        );
    }
}