import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { Masterhead } from '../components/home/Masterhead';
import { ServicesContainer } from '../components/home/work/ServicesContainer';
import { ServicesItem } from '../components/home/cards/ServicesItem';
import { ButtonCustom } from '../components/home/bootstrap/ButtonCustom';
import { ThanksContainer } from '../components/home/thanks/ThanksContainer';
import { InfoContainer } from '../components/home/info/InfoContainer';
import { StepContainer } from '../components/home/steps/StepContainer';
import { Slider } from '../components/home/Slider';
import { Clients } from '../components/home/Clients';
import { Form } from '../components/home/Form';
import { PostContainer } from '../components/home/PostContainer';
import { PostTitle } from '../components/home/PostTitle';
import { PostDate } from '../components/home/PostDate';
import { PostReadMore } from '../components/home/PostReadMore';
import { Section } from '../components/shared/Section';
import { Contact } from '../components/shared/Contact';
import { Icon } from '../components/shared/Icon';
import { Footer } from '../components/shared/Footer';

import arrowRight from '../assets/svg/arrowRight.png';
import arrowLeft from '../assets/svg/arrowLeft.png';
import syscom from '../assets/img/Syscom.png';
import JsHelper from '../helpers/JsHelper';
import ServerHelper from '../helpers/ServerHelper';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props){
    super(props);
    this.state = {
      serviceRender: <div></div>,
      blogs: []
    }
  }

  componentDidMount(){
    this.GetBlogs();
  }

  GetBlogs = async() => {
    const response = await fetch(`${ServerHelper.ApiUrl}/blog/getlasts`);

    if(response.status === 200){
        let blogs = await response.json();
        console.log(blogs);
        this.setState({
            blogs: blogs
        });
    }
  }

  renderBlog(blogs){
    return(
        <Fragment>
        {blogs.map(blog =>
            <PostContainer css="col-md-6" key={blog.id}>
                <Link to={`/blog/entry/${blog.id}`} style={{ textDecoration: 'none', color:"#000" }}>
                    <div>
                        <PostTitle>{blog.titulo}</PostTitle>
                        <PostDate>{JsHelper.dateFormatting(blog.fecha)}</PostDate>
                    </div>
                    <div>
                        <PostReadMore></PostReadMore>
                    </div>
                </Link>
            </PostContainer>
        )}
        </Fragment>
    );
}

  SetActive(nombre){
    let pressedService = document.querySelector("#"+nombre);
    let servicesItems = document.querySelectorAll(".service2Item");
    servicesItems.forEach(el => el.classList.remove("service2-active"));
    pressedService.classList.add("service2-active");
  }

  SetServiceRender(service){
    this.setState({
      serviceRender: <ServicesContainer service={service}/>
    });
  }

  AutoClick(el){
    if(el !== undefined){
      if(el !== null){
        el.click();
      }
   }
  }

  render () {
      let blogs = this.renderBlog(this.state.blogs);
      return (
        <div>
          <Masterhead/>
          <Contact/>
          <Section id="info"> 
            <div id="carouselExampleFade" className="bg-light carousel slide w-100 sectionPadding" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="anima_up carousel-inner p-5 text-uppercase fjalla">
                <div className="carousel-item active">
                  <p className="d-block w-100 infoCarousel">El mejor aliado para asegurar tus espacios</p>
                </div>
                <div className="carousel-item">
                  <p className="d-block w-100 infoCarousel">Optimice sus recursos con grandes soluciones</p>
                </div>
                <div className="carousel-item">
                  <p className="d-block w-100 infoCarousel">Aseguramos tus espacios con equipo y soporte profesional</p>
                </div>
              </div>
              <a className="carousel-control carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                <span><img src={arrowLeft} className="carouselArrow" aria-hidden="true" alt="arrow left"/></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                <span><img src={arrowRight} className="carouselArrow" aria-hidden="true" alt="arrow right"/></span>
                <span className="sr-only">Next</span>
              </a>
            </div>

            <div className="infoDiv w-100 h-50 sectionPadding1">
                <InfoContainer/>
            </div>
          </Section>

          <Section id="thanks"> 
            <div className="thanksDiv w-100 sectionPadding text-white text-uppercase">
              <h3 data-aos="fade-up" data-aos-duration="1000" className="sectionTitle fjalla">Te mantenemos seguro</h3>
              <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" className="sectionDescription font-weight-bold">Brindamos alternativas de seguridad tecnológica y soluciones integrales para asegurar tu proyecto con numerosos beneficios de manera profesional</p>
              <ButtonCustom color="blue-cta" href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20deseo%20obtener%20mayor%20informaci%C3%B3n%20de%20Maytech%20Seguridad%20electr%C3%B3nica.">Ver más<i className="fas fa-chevron-right ml-3"></i></ButtonCustom>
            </div>

            <div className="w-100 py-4 thanksDescriptionDiv text-uppercase sectionPadding1">

              <div className="row">

                <div data-aos="fade-up" data-aos-duration="1000"className="col-md-6 d-flex flex-row align-items-center px-lg-5">
                  <div className="thanksItem">
                    <i className="fas fa-user-lock text-white"></i>
                  </div>
                  <div className="thanksItem2 txt-black ml-4 py-3">
                    <h4 className="mb-2 fjalla sectionSubtitle">Expertos en seguridad</h4>
                    <p className="font-weight-bold">Instalaciones para viviendas, empresas y negocios</p>
                  </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" className="col-md-6 px-0 py-1 txt-black">

                  <ThanksContainer/>

                </div>

              </div>
            </div> 
          </Section>

          <Section id="services"> 
            <div className="services1 w-100 text-center">
              <ServicesItem/>
              <ButtonCustom color="blue-cta" href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20me%20gustar%C3%ADa%20obtener%20asesor%C3%ADa%20personalizada%20sobre%20sus%20sistemas%20de%20seguridad%20electr%C3%B3nica.">Asesoría personalizada<i className="fas fa-chevron-right ml-3"></i></ButtonCustom>
            </div> 
          </Section>

          <Section id="services2"> 
            <div className="services2 w-100 sectionPadding">
              <h3 data-aos="fade-up" data-aos-duration="1000" className="sectionTitle txt-black text-uppercase fjalla">Nuestros servicios</h3>
              <div className="row text-uppercase py-3">

                <div data-aos="fade-up" data-aos-delay="" data-aos-duration="1000"className="col-md-4 d-flex justify-content-around p-0 m-0">
                  <div id="Industrial" 
                       className="service2Item service2-active col-6 px-2" 
                       ref={this.AutoClick}
                       onClick={() => {
                          this.SetActive('Industrial');
                          this.SetServiceRender('Industrial');
                         }}>
                    <i className="service2Shape shape-industrial fas fa-industry mb-3"></i>
                    <p className="service2Name f-jalla txt-black font-weight-bold">Industrial</p>
                  </div>

                  <div id="Hotelero" 
                  className="service2Item col-6 px-2" 
                  onClick={() =>  {
                      this.SetActive('Hotelero');
                      this.SetServiceRender('Hotelero');
                    }}>
                    <i className="service2Shape shape-hotelero fas fa-hotel mb-3"></i>
                    <p className="service2Name txt-black font-weight-bold">Hotelero</p>
                  </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" className="col-md-4 d-flex justify-content-around p-0 m-0">
                  <div id="Empresarial" 
                  className="service2Item col-6 px-2" 
                  onClick={() => {
                      this.SetActive('Empresarial');
                      this.SetServiceRender('Empresarial');
                    }}>
                    <i className="service2Shape shape-empresarial fas fa-building mb-3"></i>
                    <p className="service2Name txt-black font-weight-bold">Empresarial</p>
                  </div>

                  <div id="Arquitecto" 
                  className="service2Item col-6 px-2" 
                  onClick={() => {
                      this.SetActive('Arquitecto');
                      this.SetServiceRender('Arquitecto');
                    }}>
                    <i className="service2Shape shape-arq fas fa-hard-hat mb-3"></i>
                    <p className="service2Name txt-black font-weight-bold">Arquitecto contratista</p>
                  </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000" className="col-md-4 d-flex justify-content-around justify-content-md-start justify-content-lg-start p-0 m-0">
                  <div id="Hogar" 
                  className="service2Item col-6 px-2" 
                  onClick={() => {
                      this.SetActive('Hogar');
                      this.SetServiceRender('Hogar');
                    }}>
                    <i className="service2Shape shape-hogar fas fa-home mb-3"></i>
                    <p className="service2Name txt-black font-weight-bold">Hogar y condominios</p>
                  </div>
                </div>

              </div>
            </div>

            <div className="services2Div w-100 sectionPadding1">
              {this.state.serviceRender}
            </div>

          </Section>

          <Section id="solutions" css="solutionsDiv"> 
            <div className="solutionsText w-100 sectionPadding text-white text-uppercase">
              <h3 data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" className="sectionTitle fjalla">Soluciones integrales</h3>
              <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" className="sectionDescription">Realizamos plan de seguridad integral, con profesionales capacitados en gestión y desarrollo de proyectos</p>
              <ButtonCustom color="blue-cta" href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20me%20gustar%C3%ADa%20cotizar%20un%20proyecto%20de%20seguridad%20electr%C3%B3nica.">Cotizar mi proyecto<i className="fas fa-chevron-right ml-3"></i></ButtonCustom>
            </div>
            <StepContainer/>
          </Section>

          <Section id="about"> 
            <div className="aboutDiv sectionPadding text-white text-uppercase">
              <h3 data-aos="fade-up" data-aos-delay="" data-aos-duration="1000" className="sectionTitle fjalla">Maytech seguridad</h3>
              <div className="aboutDiv1">
                <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" className="sectionDescription pr-lg-5">Venta e instalación de equipos de seguridad, control y automatización con tecnología de vanguardia <br/> <br/>
                Contamos con capital humano, capacitado para generar soluciones eficientes para nuestros clientes
                </p>
                <img data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" className="logoSyscom mb-3" src={syscom} alt="logo syscom"/><br></br>
                <ButtonCustom color="blue-cta mb-2 mr-2" href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20deseo%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20venta%20de%20equipos%20de%20seguridad.">Comprar equipo<i className="fas fa-chevron-right ml-3"></i></ButtonCustom>
                <ButtonCustom color="transparent mb-2" href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20deseo%20obtener%20mayor%20informaci%C3%B3n%20de%20Maytech%20Seguridad%20electr%C3%B3nica.">Más información<i className="fas fa-chevron-right ml-3"></i></ButtonCustom>
              </div>
            </div>

            <div className="aboutBrandDiv sectionPadding1 w-100 d-flex align-items-center flex-column relative">
              <h4 className="txt-black text-uppercase fjalla sectionSubtitle pt-md-2 pt-lg-0">Trabajamos con las mejores marcas</h4>
              <Slider/>
            </div> 
          </Section>

          <Section id="contact" css="contactDiv sectionPadding2"> 
              <h3 data-aos="fade-up" data-aos-duration="1000" className="sectionTitle text-white text-uppercase fjalla">Contacto e información</h3>
              <p data-aos="fade-up" data-aos-duration="1000" className="sectionDescription text-white text-uppercase pr-5">Disfrute de todos los beneficios al trabajar con nosotros, el mejor aliado para tu seguridad</p>
                <div  className="row contactContainer d-flex justify-content-between">
                  <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" className="checkListContainer col-md-6 text-white p-0 my-lg-5">

                    <div className="d-flex align-items-center checkList pb-4">
                      <i className="checkItem fas fa-check"></i>

                      <div className="checkDescription">
                        <h4 className="text-uppercase m-0 font-weight-bold">Expertos en seguridad</h4>
                        <p className="m-0">Para uso doméstico, comercial e industrial</p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center checkList pb-4">
                      <i className="checkItem fas fa-check"></i>
                      
                      <div className="checkDescription">
                        <h4 className="text-uppercase m-0 font-weight-bold">Satisfacción asegurada</h4>
                        <p className="m-0">Ofrecemos 1 año de garantía en nuestra línea</p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center checkList pb-4">
                      <i className="checkItem fas fa-check"></i>
                      
                      <div className="checkDescription">
                        <h4 className="text-uppercase m-0 font-weight-bold">Marcas líderes</h4>
                        <p className="m-0">Empleamos las mejores marcas en seguridad</p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center checkList pb-4">
                      <i className="checkItem fas fa-check"></i>
                      <div className="checkDescription">
                        <h4 className="text-uppercase m-0 font-weight-bold">24/7 soporte técnico</h4>
                        <p className="m-0">Contamos con soporte técnico especializado</p>
                      </div>
                    </div>

                  </div>
                  <Form/>
                </div>
          </Section>

          <Section id="clients" css="clientsDiv sectionPadding">
              <h3 data-aos="fade-up" data-aos-duration="1000" className=" sectionTitle txt-black text-uppercase fjalla">Nuestros clientes maytech</h3>
              <div className="row d-flex align-items-center py-md-3">
                <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 px-0">
                  <h4 className="txt-black text-uppercase fjalla">Tu mejor aliado en seguridad</h4>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 px-0">
                  <ul className="list-unstyled txt-black text-uppercase font-weight-bold">
                    <li className="hover_check check_1">5 años de experiencia</li>
                    <li className="hover_check check_2">Cobertura regional en el sureste mexicano</li>
                    <li className="hover_check check_3">Venta de equipo a nivel nacional</li>
                    <li className="hover_check check_4">Análisis y gestión de proyectos especializados</li>
                  </ul>
                </div>
                <Clients></Clients>
              </div>
          </Section>

          <Section id="bottom" css="footerDiv"> 
            <div className="blogDiv w-100 sectionPadding">
              <h3 data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" className="sectionTitle text-white text-uppercase fjalla">Mantente actualizado</h3>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                {blogs}
              </div>
              <Link to="/blog" style={{ textDecoration: 'none', color:"#000" }}>Ver todas las entradas<Icon icon="chevron-right" css="ml-2"></Icon></Link>
            </div>

            <Footer></Footer>
          </Section>
        </div>
    );
  }
}
