import React from 'react';
import {InfoItem} from './InfoItem';
import {InfoIcon} from './InfoIcon';
import {InfoNumber} from './InfoNumber';
import {InfoDescription} from './InfoDescription';
import { Icon } from '../../shared/Icon';

export class InfoContainer extends React.Component {
  
  render(){
      return(
        <div className="row text-uppercase text-white font-weight-bold">

          <InfoItem aos="fade-up" delay="0" duration="1000">
            <InfoIcon>
              <Icon icon="building"></Icon>
            </InfoIcon>
            <InfoNumber css="borderR">146</InfoNumber>
            <InfoDescription>Espacios seguros</InfoDescription>
          </InfoItem>

          <InfoItem aos="fade-up" delay="200" duration="1000">
            <InfoIcon>
              <Icon icon="shield-alt"></Icon>
            </InfoIcon>
            <InfoNumber css="borderR">791</InfoNumber>
            <InfoDescription>Sistemas de seguridad instalados</InfoDescription>
          </InfoItem>

          <InfoItem aos="fade-up" delay="400" duration="1000">
            <InfoIcon>
              <Icon icon="shopping-cart"></Icon>
            </InfoIcon>
            <InfoNumber css="borderR">1072</InfoNumber>
            <InfoDescription>Equipos profesionales vendidos</InfoDescription>
          </InfoItem>

          <InfoItem aos="fade-up" delay="600" duration="1000">
            <InfoIcon>
              <Icon icon="wrench"></Icon>
            </InfoIcon>
            <InfoNumber>960</InfoNumber>
            <InfoDescription>Pólizas de mantenimiento</InfoDescription>
          </InfoItem>

        </div>
      );
  }
}