import React from 'react';
import { Link } from "react-scroll";

export class ServicesItemContainer extends React.Component{
  render(){
    return(
      <Link
      style={{ textDecoration: 'none', cursor: 'pointer' }}
      className={`serviceItem col-md-3 p-md-2 mx-2 mb-lg-2 ${this.props.anima} ${this.props.anima}`}
      activeClass="none"
      to="services2"
      spy={true}
      smooth={true}
      offset={0}
      duration={500}
      >
          {this.props.children}
      </Link> 
    );
  }
}

    