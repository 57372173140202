import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/shared/Footer';
import JsHelper from '../helpers/JsHelper';

export class Privacy extends React.Component {

    componentDidMount(){
	    window.scrollTo(0, 0);
    }

    render(){
        return(
            <div>
                <header className="bg-gray-200 text-black py-16 md:py-20 lg:py-24">
                    <div className="top-border"></div>
                    <div className="px-10 md:px-20 lg:px-64">
                        <h4 className="py-2 md:py-4 text-sm md:text-base text-gray-500">Última modificación: 18/08/2021</h4>
                        <h3 className="font-bold text-lg">Maytech Seguridad</h3>
                        <h1 className="text-xl md:text-3xl lg:text-6xl">AVISO DE PRIVACIDAD PARA CLIENTES</h1>
                        <h4 className="italic py-2 md:py-4 text-sm md:text-base">Última actualización: Agosto 18, 2021</h4>
                    </div>
                </header>

                <main className="text-black px-10 md:px-20 lg:px-64 text-sm md:text-base lg:text-lg my-10">
                    <p>Maytech Seguridad, con domicilio en Avenida 35, 10b Núm. Colonia Sac Pacal, Playa del Carmen, Quintana Roo, México, C.P. 77720 y portales de internet www.elementmanagement.mx, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p><br/>
                    <h4 className="font-semibold">¿Para qué fines utilizaremos sus datos personales?</h4>
                    <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                    <ul className="list-disc p-6">
                        <li>Dar seguimiento a solicitudes de información y/o productos que nuestra empresa ofrece.</li>
                        <li>Realizar las cotizaciones y enviarle las propuestas que usted nos solicite.</li>
                        <li>Elaborar los documentos que amparan la venta y la entrega de los productos y servicios que usted nos solicita. </li>
                        <li>Proporcionar atención a las solicitudes postventa que usted nos haga. </li>
                    </ul>
                    <p>De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención: </p>
                    <ul className="list-disc p-6">
                        <li>Solicitarle su evaluación y retroalimentación sobre el servicio y los productos que le ofrecemos.</li>
                        <li>Envío de promociones y publicidad de nuevos productos y servicios que pudieran ser de su interés.</li>
                    </ul>
                    <p className="uppercase">La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p><br/>
                    <h4 className="font-semibold">¿Qué datos personales utilizaremos para estos fines?</h4>
                    <p>Para llevar a cabo las finalidades descritas en el presente Aviso de privacidad, utilizaremos los siguientes datos personales:</p>
                    <ul className="list-disc p-6">
                        <li>Datos de identificación</li>
                        <li>Datos de contacto </li>
                        <li>Datos patrimoniales y/o financieros</li>
                    </ul>
                    <p>Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos con la finalidad de evitar el uso o divulgación no autorizados de sus datos, permitiéndonos tratarlos debidamente.</p><br/>
                    <p className="uppercase">Así mismo, le informamos que sus datos personales pueden ser Transmitidos para ser tratados por personas distintas a esta empresa.</p><br/>
                    <p>Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el país, por ello le informamos que usted tiene en todo momento los derechos (ARCO) de acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus datos personales; derecho que podrá hacer valer a través del Área de Privacidad encargada de la seguridad de datos personales en el Teléfono 984 287 9394 A través de estos canales usted podrá actualizar sus datos y especificar el medio por el cual desea recibir información, ya que, en caso de no contar con esta especificación de su parte, Maytech Seguridad establecerá libremente el canal que considere pertinente para enviarle información.</p><br/>
                    <p>Este aviso de privacidad podrá ser modificado por Maytech Seguridad dichas modificaciones serán oportunamente informadas a través de correo electrónico, teléfono, página web o cualquier otro medio de comunicación que Maytech Seguridad determine para tal efecto.</p>
                    <br />
                    <Link to="/" className="font-semibold">Volver a la página principal</Link>
                </main>
                <Footer/>
            </div>
            
        )
    }
        
   
}