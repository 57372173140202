import React from 'react';
import logo from '../../assets/img/Logo.png';
import { Link } from "react-scroll";
import arrowDown from '../../assets/svg/arrowDown.png';

export class Masterhead extends React.Component {
    render() {
        return(
            <header id="home" className="masthead">
                <div id="homeText" className="container px-0 mb-3">
                    <img className="mb-3 animate__animated animate__fadeInLeft animate__delay-1s" src={logo} alt="logo maytech"/>
                    <div className="d-flex flex-row align-items-center animate__animated animate__fadeInLeft animate__delay-2s">
                        <div className="divisionLine mr-2"></div>
                        <div className="masthead-subheading text-uppercase font-weight-bold">Sistemas diseñados para tu necesidad</div>
                    </div>
                    <div className="masthead-heading text-uppercase">SEGURIDAD ELECTRÓNICA PARA <div className="titleHighlighted font-weight-bold animate__animated animate__fadeInLeft animate__delay-1s">TUS PROYECTOS</div></div>
                </div>
                <Link
                id="arrowHome"
                style={{ cursor: 'pointer' }}
                className="nav-link"
                activeClass="active"
                to="info"
                spy={true}
                smooth={true}
                offset={0}
                duration={900}
                >
                    <img className="arrowDown" src={arrowDown} alt="arrow down"/>
                </Link> 
            </header>
        );
    }
}
