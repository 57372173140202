import React from 'react';

export class PostContainer extends React.Component {
    render(){
        return(
            <div className={`blogItem d-flex flex-column justify-content-between bg-light mr-2 mb-2 p-4 ${this.props.css}`}>
                {this.props.children}
            </div>
        );
    }
}