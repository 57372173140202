import React from 'react';

export class ServicesTitle extends React.Component{
  render(){
    return(
      <h4 className="py-0 px-lg-4 txt-black">
        {this.props.children}
      </h4>
    );
  }
}

    