import CookieHelper from "../helpers/CookieHelper";

const UsuarioService = {
    IsAuthenticated: false,
    Login: (user, pass) => {
        let loginSuccessful = true;
        const exists = UsuarioService.Users.find(i => i.user === user && i.pass === pass);
        if(exists){
            CookieHelper.Set("admin", exists);
            UsuarioService.IsAuthenticated = true;
            window.location.href = "/";
        }
        else{
            loginSuccessful = false;
        }

        return loginSuccessful;
    },
    Logout: () => {
        CookieHelper.Remove("admin");
        UsuarioService.IsAuthenticated = false;
        window.location.href = "/";
    },
    Get: () => {
        const admin = CookieHelper.Get("admin");
        return admin;
    },
    Users: [
        { 
            id: 1,
            user: "maytech", 
            //poner en Tohoku51@ para produccion
            pass: "Tohoku51@" 
        }
    ]
}

export default UsuarioService;