import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/shared/Layout';
import { Home } from './pages/Home';
import { Blog } from './pages/Blog';
import { Privacy } from './pages/Privacy';
import { LogIn } from './pages/admin/LogIn';
import { Dashboard } from './pages/admin/Dashboard';
import { AddPost } from './pages/admin/blog/AddPost';
import { PostList } from './pages/admin/blog/PostList';
import { EditPost } from './pages/admin/blog/EditPost';
import { BlogPost } from './pages/blog/BlogPost';

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/blog/entry/:id' component={BlogPost} />
        <Route exact path='/blog' component={Blog} />
        <Route path='/admin/login' component={LogIn} />
        <Route path='/admin/dashboard' component={Dashboard} />
        <Route path='/admin/blog/list' component={PostList} />
        <Route path='/admin/blog/addpost' component={AddPost} />
        <Route path='/admin/blog/editpost/:id' component={EditPost} />
        <Route path='/privacy' component={Privacy} />
      </Layout>
    );
  }
}
