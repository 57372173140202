import React from 'react';
import logo from '../../assets/img/Logo.png';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ServerHelper from '../../helpers/ServerHelper';

export class Footer extends React.Component{
  constructor(props){
    super(props);
    this.state={
      email: ""
    }
    this.setEmail=this.setEmail.bind(this);
    this.addMember=this.addMember.bind(this);
  }
  setEmail(event){
    this.setState({
      email: event.target.value
    })
  }
  addMember = async(email) => {
    const data = {
      email_address: email
    }
    const url = `${ServerHelper.ApiUrl}/newsletter/addmember`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if(response.status === 200){
      Swal.fire({
        icon: 'success',
        title: '¡Te has subscripto exitosamente!',
        timer: 1500
      });

      this.setState({email: ""})
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Parece que algo salió mal, intentalo nuevamente',
        timer: 1500
      })
    }
  }
  render(){
      return(
          <div className="footerDiv row d-flex align-items-start w-100 sectionPadding1">
            
              <div data-aos="fade-up" data-aos-duration="1000" className="col-md-4 px-md-0 pt-4 mb-2">
                <img src={logo} alt="logo maytech" className="mb-2"/>
                <p className="text-white font-weight-lighter pl-0 pr-5 mb-3 md-mb-5">Venta e instalación de equipos de seguridad, control y automatización con tecnología de vanguardia</p>
                <div className="rrss">
                  <a href="https://www.facebook.com/maytechseguridad" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                  <a href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20deseo%20obtener%20mayor%20informaci%C3%B3n%20de%20Maytech%20Seguridad%20electr%C3%B3nica."><i className="fab fa-whatsapp"></i></a>
                  <a href="https://www.instagram.com/maytechseguridad/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                  <a href="https://www.linkedin.com/company/maytechplaya/about/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-duration="1000" className="col-md-4 pt-4 mb-2">
                <h5 className="footerTittle text-white text-uppercase fjalla">Contáctanos</h5>
                <div className="footerLine mb-2 mb-lg-4"></div>
                <p className="text-white font-weight-lighter">Playa del Carmen, México<br/>
                <span className="text-white font-weight-lighter">Tulum, México</span><br/>
                <span className="text-white font-weight-lighter">Cancún, México</span><br/>
                  teléfono : <span className="font-weight-bold">+(52) 984 287 9394</span><br/>
                  email : <span className="font-weight-bold">contacto@maytechseguridad.com</span>
                </p>
                <Link to="/privacy" className="font-weight-bold text-white mt-3"><p>Acuerdo de Privacidad</p></Link>
              </div>

              <div data-aos="fade-up" data-aos-duration="1000" className="col-md-4 pr-md-0 pt-2 pt-md-4 pb-sm-3 mb-2">
                <h5 className="footerTittle text-white text-uppercase fjalla">Suscríbete</h5>
                <div className="footerLine mb-2 mb-lg-4"></div>
                <p className="text-white pr-lg-4 font-weight-lighter">Suscríbete y recibe increíbles descuentos</p>
                <input 
                value={this.state.email}
                type="email" 
                className="mb-3 px-2" 
                placeholder="Déjanos tu correo electrónico" 
                onChange={this.setEmail}>
                </input><br/>
                <button type="button" className="font-weight-bold text-uppercase mb-3" onClick={()=>this.addMember(this.state.email)}>Enviar<i className="fas fa-chevron-right ml-3"></i></button>
              </div>

              <Link to="/admin/login" className="col m-0 p-0">
                <p className="copyright font-weight-light text-white">
                    <i className="fas fa-user-circle mr-2"></i>
                  <span className="font-weight-bold">Maytech Seguridad © 2021</span> | Todos los derechos reservados
                </p>
              </Link>

          </div>
      );
  }
}