import React from 'react';
import { ServicesItemContainer } from './ServicesItemContainer';
import { ServicesTitle } from './ServicesTitle';
import { ServicesDetails } from './ServicesDetails';
import { Image } from '../../shared/Image';
import venta from '../../../assets/img/Venta.png';
import seguridad from '../../../assets/img/Seguridad.png';
import control from '../../../assets/img/Control.png';
import cctv from '../../../assets/img/CCTV.png';
import intrusion from '../../../assets/img/Intrusion.png';



export class ServicesItem extends React.Component{
  render(){
    return(
      <div className="row sectionPadding text-center d-flex justify-content-center text-uppercase">
        
        <ServicesItemContainer anima="anima_up2">
          <div className='serviceItem_div'>
            <Image css="pt-2 pb-2" src={venta} alt="ventas"/>
          </div>
          <ServicesTitle>Venta de <br/>equipo</ServicesTitle>
          <ServicesDetails>Equipo profesional para tu proyecto</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <div className='serviceItem_div'>
            <Image css="pt-2" src={seguridad} alt="seguridad"/>
          </div>
          <ServicesTitle>Seguridad integral</ServicesTitle>
          <ServicesDetails>Soluciones completas en sistemas de seguridad</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up2">
          <div className='serviceItem_div'>
            <Image css="pt-2 pb-2" src={control} alt="control de hogar"/>
          </div>
          <ServicesTitle>Control de <br/>acceso</ServicesTitle>
          <ServicesDetails>Restringe el acceso con tecnología de gama</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <div className='serviceItem_div'>
            <Image css="pt-2 pb-3 mt-2" src={cctv} alt="cctv"/>
          </div>
          <ServicesTitle>Cctv</ServicesTitle>
          <ServicesDetails>Vigila tu casa o negocio desde el celular</ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <div className='serviceItem_div'>
            <Image css="pt-2" src={intrusion} alt="intrusion"/>
          </div>
          <ServicesTitle>Intrusión</ServicesTitle>
          <ServicesDetails>Prevención automática de robo</ServicesDetails>
        </ServicesItemContainer>

      </div>
    );
  }
}

    