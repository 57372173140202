const JsHelper = {
    /**
     * Esta funcion devuelve un elemento por Id
     * @param {string} id 
     * @returns {HTMLElement}
     */
    getById: (id="") => {
        return document.getElementById(id);
    },
    getBySelector: (selector="") => {
        return document.querySelector(selector);
    },
    getAllBySelector: (selector="") => {
        return document.querySelectorAll(selector);
    },
    getByClass: (clase="") => {
        return document.getElementsByClassName(clase)[0];
    },
    getAllByClass: (clase="") => {
        return document.getElementsByClassName(clase);
    },
    getByTag: (tag="") => {
        return document.getElementsByTagName(tag)[0];
    },
    getAllByTag: (tag="") => {
        return document.getElementsByTagName(tag);
    },
    dateFormatting: (fecha) => {
        let finalDate = "";

        if(fecha !== null){
            let usDate = fecha.split("T")[0];
            let latinDate = usDate.split("-");
            finalDate = `${latinDate[2]}/${latinDate[1]}/${latinDate[0]}`;
        }
        
        return finalDate;
    }
}

export default JsHelper;