import React from 'react';
import logo from '../../assets/img/Logo.png';
import { Link } from 'react-router-dom';
import { Icon } from '../../components/shared/Icon';
import UsuarioService from '../../services/UsuarioService';
import Swal from 'sweetalert2';

export class LogIn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: "",
            pass: ""
        }

        this.setUser=this.setUser.bind(this);
        this.setPass=this.setPass.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount(){
        // COMENTO ESTA LINEA PARA COMPLILAR Y QUE NO HAYA ACCESO AL ADMIN
        if(UsuarioService.IsAuthenticated){
            this.props.history.push('/');
        }
        //this.props.history.push('/');
    }

    setUser(event){
        this.setState({
            user: event.target.value
        });
    }

    setPass(event){
        this.setState({
            pass: event.target.value
        });
    }

    login(){
        const ok = UsuarioService.Login(this.state.user, this.state.pass);
        if(!ok){
            Swal.fire(
                {
                    icon: "error",
                    text: "Usuario o Contraseña incorrectos"
                }
            );
        }
        else{
            this.props.history.push('/');
        }
    }
    render(){
        return(
            <div className="adminContainer">
                <div className="animate__animated animate__zoomIn form-signin">
                    <img className="mt-4 mb-3" src={logo} alt="" width="100" height="72"/>
                    <h1 className="h4 my-3 font-weight-bolder text-uppercase text-white">Iniciar sesión</h1>
                    <label className="sr-only">Usuario</label>
                    <input value={this.state.user} onChange={this.setUser} type="text" id="inputUser" className="form-control" placeholder="Nombre de usuario" required autoFocus/>
                    <label className="sr-only">Contraseña</label>
                    <input value={this.state.pass} onChange={this.setPass} type="password" id="inputPassword" className="form-control" placeholder="Contraseña" required/>
                    <button className="btn btn-lg btn-blue-cta btn-block font-weight-bolder text-uppercase" type="button" onClick={this.login}>Ingresar<i className="fas fa-chevron-right ml-3"></i></button>
                    <Link to="/" style={{ textDecoration: 'none', color:"#fff", marginTop: "1rem" }}><Icon icon="chevron-left" css="mr-2"></Icon>Volver al inicio</Link>
                    <p className="text-white mt-5 mb-3">Maytech Seguridad &copy; 2021</p>
                </div>
            </div>            
        );
    }
}